import React from 'react';
import { observable, action, makeObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import PropTypes from 'prop-types';
import uniqWith from 'lodash/uniqWith';
import isEqualWith from 'lodash/isEqualWith';

import MentionsTextArea from './MentionsTextArea';
import AccountSuggestion from '@vectorworks/vcs-ui/dist/lib/Input/AccountSuggestion';

import CollaboratorsStore from './collaborators-store';

import { Dialog } from '../base/dialog';
import validators from '../lib/validators';
import utils from './utils';

const ENTER_KEY = 13;
const ESC_KEY = 27;

class MentionsField extends React.Component {
    selectedIndex;
    currentWord;
    currentContainer;

    get suggestionsList () {
        const withAccess = this.props.commentsStore.withAccess;
        return uniqWith([...CollaboratorsStore.collaboratorsList, ...withAccess], isEqualWith);
    };

    get suggestions () {
        let newSuggestions = [];
        const matches = (field, text) => field.toLowerCase().startsWith(text.toLowerCase());

        if (this.currentMatch) {
            newSuggestions = this.suggestionsList.filter(s =>
                s.fullName && matches(s.fullName, this.currentMatch) ||
                s.email && matches(s.email, this.currentMatch) ||
                s.login && matches(s.login, this.currentMatch)
            );
        } else {
            newSuggestions = this.suggestionsList;
        }
        return newSuggestions;
    }

    get hasSuggestions () {
        return this.suggestions.length > 0;
    }

    get currentMatch () {
        return (this.currentWord.startsWith('@') && this.currentWord.length > 1)
            ? this.currentWord.substring(1, this.currentWord.length)
            : '';
    }

    get showDropdown () {
        return this.currentWord.startsWith('@');
    }

    constructor (props) {
        super(props);

        makeObservable(this, {
            selectedIndex: observable,
            currentWord: observable,
            currentContainer: observable,
            updateCurrentWord: action,
            exitMentioning: action
        });

        this.srollAnimationTime = 60;
        this.selectedIndex = 0;
        this.cursor = 0;
        this.currentWord = '';
    };

    componentDidMount () {
        this.textareaElement = this.textarea.getEditableDiv();
    };

    setTextAreaContent = (text = '') => {
        this.textareaElement.innerHTML = text;
    };

    onKeyDown = event => {
        this.updateCaretPosition();
        if (!this.showDropdown && !event.shiftKey) {
            if (event.keyCode === ENTER_KEY) {
                event.preventDefault();
                this.props.onEnter(event)
                    .then(() => {
                        this.exitMentioning();
                        this.setTextAreaContent();
                    })
                    .catch(err => console.log(err));
            }
            if (event.keyCode === ESC_KEY) {
                this.props.onEscape();
                this.exitMentioning();
                this.setTextAreaContent();
            }
        }

        this.showDropdown && this.keyAction(event);
    };

    onInput = () => {
        this.updateCurrentWord();
        this.updateCaretPosition();
    };

    onFocus = () => {
        if (!CollaboratorsStore.loaded) {
            CollaboratorsStore.getCollaborators();
        }
    };

    onPaste = e => {
        e.preventDefault();
        const text = e.clipboardData.getData('text/plain');

        // insert text manually
        document.execCommand('insertHTML', false, text);

        // simulate keydown event to update currentWord and currentContainer
        let event = new Event('keydown'); // eslint-disable-line
        event.key = 'ArrowDown';
        event.keyCode = event.key.charCodeAt(65);
        e.which = e.keyCode;
        e.altKey = false;
        e.ctrlKey = true;
        e.shiftKey = false;
        e.metaKey = false;
        e.bubbles = true;
        document.dispatchEvent(event);
    };

    updateCurrentWord = () => {
        if (!this.currentContainer) return;

        const text = this.currentContainer.textContent;
        const words = text.split(' ');
        let wordStart = 0;

        for (let i = 0; i < words.length; i++) {
            if (wordStart + words[i].length >= this.cursor) {
                this.currentWord = text.substring(wordStart, wordStart + words[i].length).trim();
                return;
            }
            wordStart += words[i].length + 1;
        }
    };

    keyAction = event => {
        const keyActions = {
            13: e => { // enter
                e.preventDefault();
                if (this.hasSuggestions) {
                    const match = this.suggestions[this.selectedIndex];
                    this.selectUser(match);
                } else if (this.currentMatch && validators.email(this.currentMatch)) {
                    this.selectUser({ email: this.currentMatch });
                }
            },
            38: e => {
                e.preventDefault(); // arrow up
                if (!this.showDropdown) return;
                if (this.selectedIndex - 1 > -1) {
                    this.selectedIndex = (this.selectedIndex - 1);
                    this.moveScroll();
                }
            },
            40: e => { // arrow down
                e.preventDefault();
                if (!this.showDropdown) return;
                if (this.selectedIndex + 1 < this.suggestions.length) {
                    this.selectedIndex = (this.selectedIndex + 1);
                    this.moveScroll();
                }
            },
            27: e => { // escape
                e.preventDefault();
                this.exitMentioning();
            },
            32: e => { // space
                this.exitMentioning();
            }
        };

        !event.shiftKey && keyActions[event.which] && runInAction(() => keyActions[event.which](event));
    };

    moveScroll = () => {
        this.activeOption = this.$dropdownContent.find('.suggestion').get(this.selectedIndex);

        const heightMenu = this.$dropdownContent.height();
        const heightItem = $(this.activeOption).outerHeight(true);
        const scroll = this.$dropdownContent.scrollTop() || 0;
        const y = $(this.activeOption).offset().top - this.$dropdownContent.offset().top + scroll;
        const scrollTop = y;
        const scrollBottom = y - heightMenu + heightItem;

        if (y + heightItem > heightMenu + scroll) {
            this.$dropdownContent.stop().animate({ scrollTop: scrollBottom }, this.srollAnimationTime);
        } else if (y < scroll) {
            this.$dropdownContent.stop().animate({ scrollTop }, this.srollAnimationTime);
        }
    };

    exitMentioning = () => {
        this.currentWord = '';
        this.selectedIndex = 0;
    };

    updateCaretPosition = () => {
        if (!window.getSelection) {
            throw new Error('!!! window.getSelection is not supported. Mentions will not work!');
        }
        let range;
        const sel = window.getSelection();
        if (sel.rangeCount) {
            range = sel.getRangeAt(0);
            if (range.commonAncestorContainer.parentNode === this.textareaElement) {
                this.currentContainer = range.startContainer;
                this.cursor = range.endOffset;
            }
        }
    };

    removeMention = (parentElement, elementToRemove) => {
        if (!elementToRemove.nextSibling) {
            const extraText = document.createTextNode('\u00A0');
            this.textareaElement.insertBefore(extraText, elementToRemove.nextSibling);
        }
        this.placeCursor(elementToRemove.nextSibling, 0);
        parentElement.removeChild(elementToRemove);
    };

    selectUser = user => {
        this.insertMentionInText(user);
        this.exitMentioning();
    };

    selectUserClicked = user => e => {
        e.preventDefault();
        this.selectUser(user);
    };

    createMention = user => {
        const title = `${user.fullName} (${user.login})`;

        return `
            <span
                class="mention"
                contenteditable="false"
                title="${user.login ? title : ''}"
                email="${user.email}"
                ${user.login ? 'login="' + user.login + '"' : ''}
            >${user.fullName || user.email}</span>
        `;
    };

    insertMentionInText = user => {
        const word = this.currentWord;
        this.props.commentsStore
            .checkAccess(utils.clearEmpty(user))
            .then(hasAccess => {
                if (hasAccess) {
                    const mention = this.createMention(user);
                    const fragment = document.createDocumentFragment();
                    let currentContainer = this.currentContainer.parentNode.firstChild;
                    let textAfterMention = null;
                    while (currentContainer !== null) {
                        const nextSibling = currentContainer.nextSibling;
                        if (currentContainer === this.currentContainer) {
                            const indexBeforeMention = currentContainer.textContent.substring(0, this.cursor).lastIndexOf(' ');
                            const contentPart1 = `${currentContainer.textContent.substring(0, indexBeforeMention)}`; // part of string before mention
                            const contentPart2 = `${currentContainer.textContent.substring(indexBeforeMention + word.length + 2, currentContainer.textContent.length)}`; // string after mention
                            fragment.appendChild(document.createTextNode(`${contentPart1} `));
                            fragment.appendChild($(mention)[0]);
                            textAfterMention = document.createTextNode(` ${contentPart2}`);
                            fragment.appendChild(textAfterMention);
                        } else {
                            fragment.appendChild(currentContainer);
                        }
                        currentContainer = nextSibling;
                    }
                    this.textareaElement.innerHTML = '';
                    this.textareaElement.appendChild(fragment);
                    this.placeCursor(textAfterMention, 0);
                } else {
                    Dialog.alert({ text: gettext('Only the file owner can @mention people who are not part of the current share.') });
                }
            })
            .catch(err => console.log(err));
    };

    placeCursor = (element, offset = 0) => {
        const range = document.createRange();
        const sel = window.getSelection();
        range.setStart(element, offset);
        range.collapse(true);
        sel.removeAllRanges();
        sel.addRange(range);
        this.textareaElement.focus();
    };

    renderSuggestion = (s, i) =>
        (<AccountSuggestion
            key={s.login ? s.login + s.email : s.email}
            account={s}
            isSelected={i === this.selectedIndex}
            search={this.currentMatch}
            className='suggestion'
            active={i === this.selectedIndex}
            onClick={this.selectUserClicked(s)}
        />);

    renderNoMatches = () =>
        (<div className='suggestion-message'>
            { gettext('No matches. Keep typing an email address.') }
        </div>);

    renderSuggestionList = () =>
        (<div className='suggestions-list'>
            { this.hasSuggestions && this.suggestions.map(this.renderSuggestion) }
        </div>);

    renderDropdown = () =>
        (<div
            className='suggestions-dropdown'
            ref={dd => { this.$dropdownContent = $(dd); }}
        >
            { !this.hasSuggestions && this.renderNoMatches()}
            { this.hasSuggestions && this.renderSuggestionList() }
        </div>);

    render () {
        return (
            <div className='mentions-field'>
                <MentionsTextArea
                    ref={textarea => { this.textarea = textarea; }}
                    placeholder={this.props.placeholder}
                    onChange={this.props.onChange}
                    onKeyDown={this.onKeyDown}
                    onInput={this.onInput}
                    onPaste={this.onPaste}
                    onBlur={this.props.onBlur && this.props.onBlur}
                    onFocus={this.onFocus}
                />
                { this.showDropdown && this.renderDropdown() }
            </div>
        );
    }
}

export default observer(MentionsField);

MentionsField.propTypes = {
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    onEnter: PropTypes.func,
    onEscape: PropTypes.func,
    onBlur: PropTypes.func,
    commentsStore: PropTypes.object.isRequired
};
