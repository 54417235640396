import React from 'react';

import { Observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { bindHover, bindPopover } from 'material-ui-popup-state';
import { usePopupState } from 'material-ui-popup-state/hooks';
import HoverPopover from 'material-ui-popup-state/HoverPopover';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { Slider } from '@vectorworks/vcs-ui/dist/lib/Slider/Slider';

const VideoVolume = ({ viewer }) => {
    const popupState = usePopupState({
        variant: 'popover',
        popupId: 'volumePopup'
    });
    return (
        <Observer>{() => (
            <React.Fragment>
                <IconButton
                    {...bindHover(popupState)}
                    onClick={viewer.toggleVolume}
                    ga-action='File_View_Controller'
                    ga-label='Click_Toggle_Volume'
                    data-what='controller-tool'
                    data-which='toggle-volume'
                    id='toggle-volume'
                >
                    <Icon icon={viewer.volume ? 'volume-on' : 'volume-off'} />
                </IconButton>
                <HoverPopover
                    {...bindPopover(popupState)}
                    anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                    }}
                    transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                    }}
                >
                    <div
                        style={{
                            padding: 16,
                            height: 160,
                            backgroundColor: 'rgba(var(--grey13-rgb), 0.75)'
                        }}
                    >
                        <Slider
                            orientation='vertical'
                            onChange={viewer.updateVolume}
                            value={(viewer.volume * 100) || 0}
                            max={100}
                            min={0}
                            step={1}
                            trackColor
                        />
                    </div>
                </HoverPopover>
            </React.Fragment>
        )}</Observer>
    );
};

VideoVolume.propTypes = {
    viewer: PropTypes.object.isRequired
};

export default VideoVolume;
