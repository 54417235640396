import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

const Navigation = ({ store }) => {
    const isMultipleSelection = store.file.group.hasNext() && store.file.group.hasPrev();

    return (
        <React.Fragment>
            <IconButton
                disabled={!store.file.group.hasPrev()}
                onClick={store.file.prev}
                title={ gettext('Previous file') }
                aria-label={ gettext('Previous file') }
                ga-action='File_View_Controller'
                ga-label='Click_Previous_File'
                data-what='controller-tool'
                data-which='prev-file'
            >
                <Icon icon='left-arrow' />
            </IconButton>

            <span className='ctrl-tool file-navigation'>
                {
                    isMultipleSelection
                        ? `${store.file.group.cursor + 1} / ${store.file.numberOfFiles}`
                        : '1 / 1'
                }
            </span>

            <IconButton
                disabled={!store.file.group.hasNext()}
                onClick={store.file.next}
                title={ gettext('Next file')}
                aria-label={ gettext('Next file')}
                ga-action='File_View_Controller'
                ga-label='Click_Next_File'
                data-what='controller-tool'
                data-which='next-file'
            >
                <Icon icon='right-arrow' />
            </IconButton>
        </React.Fragment>
    );
};

Navigation.propTypes = {
    store: PropTypes.object
};

export default observer(Navigation);
