import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Slider } from '@vectorworks/vcs-ui/dist/lib/Slider/Slider';
import styled from 'styled-components';

const S = {
    Wrapper: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;
        background-color: transparent;
        white-space: nowrap;
    `
};

const VideoProgress = ({ viewer }) =>
    <S.Wrapper>
        {!!viewer.updateTime && (
            <Slider
                disabled={!viewer.canPlay}
                onChange={viewer.updateTime}
                value={viewer.time || 0}
                max={viewer.duration}
                min={0}
                step={1}
                trackColor
            />
        )}
        {/* Progress and buffered styles? */}
        <div>
            {`${viewer.displayTime || '00:00'} / ${viewer.displayDuration || '00:00'}`}
        </div>
    </S.Wrapper>;

VideoProgress.propTypes = {
    viewer: PropTypes.object
};

export default observer(VideoProgress);
