import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Controller } from './Default';
import FullScreen from './Widgets/FullScreen';
import HandTool from './Widgets/HandTool';
import Navigation from './Widgets/Navigation';
import PDFPages from './Widgets/PDFPages';
import PDFZoom from './Widgets/PDFZoom';

const PDFController = ({ store, viewer }) =>
    <Controller store={store}>
        <div className='control-section left'>
            <PDFZoom viewer={viewer}/>
            <HandTool viewer={viewer}/>
        </div>

        <div className='control-section center'>
            <Navigation store={store}/>
        </div>

        <div className='control-section right'>
            <FullScreen store={store}/>
            <PDFPages store={store} viewer={viewer} />
        </div>
    </Controller>;

PDFController.propTypes = {
    store: PropTypes.object,
    viewer: PropTypes.object
};

export default observer(PDFController);
