import React from 'react';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import { Separator } from '@vectorworks/vcs-ui/dist/lib/Toolbar';
import { textOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';

import GlobalTools from './GlobalTools';
import ToolbarContextActions from '../Components/ToolbarContextActions';

export const S = {
    Toolbar: styled.div.attrs(({ role: 'toolbar' }))`
        display: flex;
        align-items: center;
        background-color: var(--bg-color);
        min-height: 32px;
    `,
    FileName: styled.h1.attrs(({ className = 'visible-lg-block' }) => ({
        className,
        what: 'file-name'
    }))`
        flex: 1;
        font-size: 16px;
        padding: 0 8px;
        margin: 0;

        ${textOverflow};
        font-weight: bold;
        color: var(--text-secondary-color);
    `
};

const Toolbar = ({ store }) => (
    <S.Toolbar className='toolbar fv-toolbar'>
        <S.FileName>{store.file.file.name}</S.FileName>
        <ToolbarContextActions
            items={[store.file.file]}
            actions={store.file.file.actions}
            hiddenActions={['view', 'restore', 'deleteVersion']}
        />
        <Separator style={{ marginLeft: 'auto' }} />
        <GlobalTools store={store} />
    </S.Toolbar>
);

Toolbar.propTypes = {
    store: PropTypes.object
};

export default observer(Toolbar);
