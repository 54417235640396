import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

const ImageZoom = observer(
    ({ viewer }) => viewer
        ? <span className='ctrl-tool zoom'>
            <IconButton
                onClick={viewer.zoomOut}
                title={gettext('Zoom out')}
                ga-action='File_View_Controller'
                ga-label='Click_Zoom_Out'
                data-what='controller-tool'
                data-which='zoom-out'
            >
                <Icon icon='zoom-out' />
            </IconButton>
            <span
                className='ctrl-tool'
                onClick={viewer.setActualPixelsSize}
                title={viewer.percentage === 100
                    ? gettext('Zoom to fit')
                    : gettext('View actual size')}
            >{`${viewer.percentage ? viewer.percentage : 0}%`}
            </span>
            <IconButton
                onClick={viewer.zoomIn}
                title={gettext('Zoom in')}
                ga-action='File_View_Controller'
                ga-label='Click_Zoom_In'
                data-what='controller-tool'
                data-which='zoom-in'
            >
                <Icon icon='zoom-in' />
            </IconButton>
        </span>
        : null
);

ImageZoom.propTypes = {
    viewer: PropTypes.object
};

export default ImageZoom;
