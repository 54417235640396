import React, { Component } from 'react';

import { observable, computed, action, makeObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { File } from '../../lib/file.js';
import { commonPropTypes } from './utils';
import fullScreen from '../../base/fullscreen';
import keyboard from '../../base/keyboard';
import { queryString } from '../../lib';

const makeDisplayable = time => {
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor(time / 60) % 60;
    const seconds = time % 60;

    return [hours, minutes, seconds]
        .map(v => v < 10 ? '0' + v : v)
        .filter((v, i) => v !== '00' || i > 0)
        .join(':');
};

const possiblySupportedContentTypes = ['video/mp4', 'video/x-msvideo', 'video/mpeg', 'video/quicktime', 'video/mp4'];

class VideoViewer extends Component {
    videoSrc = null;
    time = 0;
    duration = 0;
    paused = true;
    volume = 0;
    buffered = 0;
    timeDrag = false;
    muted = false;
    canPlay = true;

    sourceUrl = '';
    name = 'VideoViewer';

    get displayTime () {
        return makeDisplayable(this.time);
    }

    get displayDuration () {
        return makeDisplayable(this.duration);
    }

    get progress () {
        return `${(this.time / this.duration) * 100}%`;
    }

    constructor (props) {
        super(props);

        makeObservable(this, {
            videoSrc: observable,
            time: observable,
            duration: observable,
            paused: observable,
            volume: observable,
            buffered: observable,
            timeDrag: observable,
            muted: observable,
            canPlay: observable,
            displayTime: computed,
            displayDuration: computed,
            progress: computed,
            setVideo: action,
            canPlayType: action,
            timeUpdate: action,
            durationChange: action,
            updateTime: action,
            togglePause: action,
            toggleVolume: action,
            updateVolume: action,
            toggleFullscreen: action,
            toggleController: action
        });

        const keyEvents = {
            space: {
                predicate: event => event.which === 32,
                handler: () => this.togglePause()
            }
        };
        this.keyEvents = keyboard.peekKeyEvents();
        this.keyEvents.extend(keyEvents);
    }

    componentDidMount () {
        this.setVideo();
    }

    componentDidUpdate () {
        this.setVideo();
    }

    componentWillUnmount () {
        this.keyEvents.reduce();
        this.video.style.display = '';
    };

    setVideo = () => {
        const sourceUrl = this.props.store.file.sourceUrl;
        if (this.sourceUrl === sourceUrl) return;
        this.videoSrc = null;
        this.time = 0;
        this.duration = 0;
        this.paused = true;
        this.volume = 0;
        this.buffered = 0;
        this.timeDrag = false;
        this.muted = false;
        this.props.store.startLoading();

        const videoPromise =
            Settings.offlinePresentation
                ? Promise.resolve(this.props.store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    sourceUrl,
                    {
                        response_type: 'data',
                        viewable: 'off'
                    }
                ));

        videoPromise.then(data => {
            runInAction(() => {
                this.videoSrc = data.url;
                this.sourceUrl = sourceUrl;
                this.props.store.endLoading();
                this.canPlayType();
            });
        }, () => {
            this.props.store.endLoading();
        });
    };

    canPlayType () {
        if (!this.video) return;
        const contentType = new File(this.props.store.file.file.name, '', 0).contentType();
        this.canPlay = this.video.canPlayType(contentType).length === 'probably' ||
            possiblySupportedContentTypes.includes(contentType);
        !this.canPlay && this.video.pause();
        this.video.style.display = this.canPlay ? '' : 'none';
    };

    timeUpdate = () => {
        this.time = Math.floor(this.video.currentTime);
        this.paused = this.video.paused;
        if (this.video.readyState === 0) return;

        const buf = this.video.buffered;
        const bufLen = buf.length - 1;
        this.buffered = Math.floor(((buf.end(bufLen) - buf.start(bufLen)) / this.duration) * 100);
    };

    durationChange = () => {
        this.duration = Math.floor(this.video.duration);
        this.volume = this.video.volume;
        this.paused = this.video.paused;
        this.canPlayType();
    };

    updateTime = event => {
        this.video.currentTime = event.target.value;
        this.time = Math.floor(this.video.currentTime);
    };

    togglePause = () => {
        this.paused = !this.paused;
        this.paused ? this.video.pause() : this.video.play();
    };

    setMuted = muted => {
        this.muted = muted;
        this.video.muted = this.muted;
    };

    toggleVolume = () => {
        this.setMuted(!this.muted);
        if (this.muted) {
            this.volume = 0;
        } else {
            this.volume = this.video.volume;
        }
    };

    updateVolume = event => {
        this.setMuted(false);
        this.video.volume = event.target.value / 100;
        this.volume = this.video.volume;
    };

    toggleFullscreen = () => {
        fullScreen.toggle($('.viewer-wrapper').get(0));
        this.props.store.ui.fullScreen = !this.props.store.ui.isFullScreen;
        this.props.store.ui.controller.hide();
    };

    videoTap = () => {
        Settings.device.isMobile ? this.toggleController() : this.togglePause();
    };

    toggleController = () => {
        this.props.store.ui.controller.toggle();
    };

    render () {
        return (
            <div
                className='fileview-component-loader centered'
                data-what='file-viewer'
                {...this.props.controllerTogglers}
            >
                { this.props.children }

                {
                    !this.canPlay &&
                    <div
                        className='message-box flex-col' style={{
                            height: '100%',
                            position: 'absolute'
                        }}
                    >
                        <span className='icon icon-status-error-16'/>
                        <div className='message'>
                            <span className='msg-row-prim'>
                                { gettext('Your browser does not support the codec needed to play this video.') }
                            </span>
                        </div>
                    </div>
                }

                <video
                    ref={video => { this.video = video; }}
                    src={this.videoSrc}
                    onClick={this.videoTap}
                    autoPlay={true}
                    onDoubleClick={Settings.device.fullScreenEnabled ? this.toggleFullscreen : null}
                    onTimeUpdate={this.timeUpdate}
                    onDurationChange={this.durationChange}
                    playsInline
                    data-testid='vcs-video-viewer'
                />
            </div>
        );
    }
};

VideoViewer.defaultProps = {
    controllerTogglers: {}
};

export default observer(VideoViewer);

export {
    makeDisplayable
};

VideoViewer.propTypes = {
    ...commonPropTypes
};
