import React, { Component } from 'react';

import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';

import { addClass } from '../../lib/dom-utils';
import { commonPropTypes } from './utils';
import loadAframe from '~static/file-viewer/panorama/aframe-loader';
import PanoramaScene from '../../Components/Panorama/PanoramaScene';
import { utils } from '~static/js/lib';
import { Dialog, ConfirmDialogViewModel } from '../../../js/base/dialog';
import PanoramaController from './Controllers/PanoramaController';

function createImage (url) {
    const image = new window.Image(); // eslint-disable-line
    image.id = `panoramic-img-${utils.generateGuid()}`;
    image.crossOrigin = '';
    image.src = url;
    addClass(image, 'cached-img');
    document.body.append(image);
    return `#${image.id}`;
}

function removeImage (imageId) {
    imageId && $(imageId).remove();
}

const sceneTemplate = props => `
    <a-scene
        bind__vcs-vr-mode-ui="enabled: ${!!props.vrModeEnabled} && !!controller.vrMode"
        vr-mode-ui="enabled: false"
        embedded
    >
        <a-sky
            bind__src="image"
            rotation="0 -130 0"
            radius="10"
        ></a-sky>
        <a-entity
            class="camera"
            camera="near: 0.005; far: 20"
            position rotation
            bind__vcs-look-controls="enabled: !!controller.lookControls;
                reverseMouseDrag: true; reverseTouchDrag: true"
        >
        </a-entity>
    </a-scene>
`;

class SceneStore {
    image;
    controller = {
        lookControls: true,
        vrMode: false
    };

    constructor (screen, options = {}) {
        makeObservable(this, {
            image: observable,
            controller: observable,
            reset: action,
            setState: action,
            controlScene: action
        });

        this.reset(options);
    }

    reset (options = {}) {
        this.controller = {
            lookControls: true,
            vrMode: false
        };
        this.image = undefined;
        Object.assign(this, options);
    }

    setState (prop, value) {
        this[prop] = value;
    }

    setScene (scene) {
        this.scene = scene;
    }

    clearScene () {
        this.scene = undefined;
    }

    controlScene (prop, value) {
        this.controller[prop] = value;
    }
}

class PanoramaViewer extends Component {
    name = 'PanoramaViewer';

    constructor (props) {
        super(props);
        this.sceneRef = makeRef();
        this.state = {
            imageId: null
        };
        this.sceneStore = new SceneStore();
    };

    componentDidMount () {
        this.props.sourceUrl && this.showImage(this.props.sourceUrl);

        if (Settings.device.isMobile && Settings.device.isIOS) {
            const id = setTimeout(() => {
                // iOS 13+ devices
                if (window.DeviceOrientationEvent && typeof window.DeviceOrientationEvent.requestPermission === 'function') {
                    Dialog.open({
                        component: 'dialog-motion-sensors-request-permission'
                    }).result.then(() => {
                        window.DeviceOrientationEvent.requestPermission();
                    });
                } else {
                    // devices under iOS 13
                    Dialog.open({
                        component: 'alert',
                        ctx: {
                            template: { name: 'dialog-ios-sensors-disabled' }
                        }
                    });
                }
                this.notifySensorsDisabled &&
                    window.removeEventListener('deviceorientation', this.notifySensorsDisabled);
            }, 500);
            this.notifySensorsDisabled = () => clearTimeout(id);
            window.addEventListener('deviceorientation', this.notifySensorsDisabled);
        }
    };

    componentDidUpdate (prevProps) {
        if (this.props.sourceUrl !== prevProps.sourceUrl) {
            this.props.sourceUrl && this.showImage(this.props.sourceUrl);
        }
    }

    enterVR = () => {
        const vrBtn = document.querySelector('#a-enter-vr-button');
        vrBtn.click();
    };

    componentWillUnmount () {
        removeImage(this.state.imageId);
        this.sceneStore.reset();
    }

    showImage = imageUrl => {
        const imageId = createImage(imageUrl);
        loadAframe()
            .then(() => {
                this.setState((prevState, props) => {
                    removeImage(prevState.imageId);
                    return { imageId };
                }, () => {
                    this.sceneStore.setState('image', this.state.imageId);
                });
            });
        this.props.store && this.props.store.endLoading();
    };

    renderScene () {
        return (
            <div
                className='fileview-component-loader panorama-viewer'
                data-what='file-viewer'
                {...this.props.controllerTogglers}
            >
                { this.props.children }

                <PanoramaScene
                    sceneRef={this.sceneRef}
                    eventHandlers={{}}
                    sceneProps={{
                        ...this.props.sceneProps,
                        vrModeEnabled: true
                    }}
                    sceneStore={this.sceneStore}
                    sceneTemplate={sceneTemplate}
                />
            </div>
        );
    };

    render () {
        return (
            <React.Fragment>
                { this.state.imageId
                    ? this.renderScene()
                    : (<div className='fileview-component-loader'>
                        {this.props.children}
                    </div>) }
                {/* There file-viewer/panorama/index.js imports this viewer and passes no store there */}
                {this.props.store && <PanoramaController store={this.props.store} viewer={this} />}
            </React.Fragment>
        );
    };
};

PanoramaViewer.propTypes = {
    ...commonPropTypes
};

function makeRef () {
    const ref = current => {
        ref.current = current;
    };
    ref.current = null;
    return ref;
}

!ko.components.isRegistered('dialog-motion-sensors-request-permission') &&
    ko.components.register('dialog-motion-sensors-request-permission', {
        viewModel: ConfirmDialogViewModel,
        template: { element: 'dialog-motion-sensors-request-permission' }
    });

export default observer(PanoramaViewer);

export {
    makeRef
};
