import React, { lazy, Suspense } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

import { observer } from 'mobx-react';

import { waitForNode } from '../../lib/utils';
import BrandingLogo from '../../branding/BrandingLogo';
import KeyHandler from '../../Components/KeyHandler';
import { pubsub } from '../../base';
import { queryString } from '../../lib';
import { DefaultController } from './Controllers/Default';

const Viewer = lazy(() => import('@vectorworks/vcs-ccad').then(mod => ({ default: mod.Viewer })));

const CCADViewer = ({ store }) => {
    const contentRef = React.useRef();
    // const viewerRef = useRef({}); Connection between ccad and vcs-pdf-viewer
    const [, setBypassEvents] = React.useState(false);
    const [sourceUrl, setSourceUrl] = React.useState(null);

    const [showBranding, setShowBranding] = React.useState(null);
    const commentsStore = store.file.commentsStore;

    React.useEffect(() => {
        getDocumentSourceUrl();
        commentsStore.load();
    }, [store.file.sourceUrl]);

    React.useEffect(() => {
        pubsub.subscribe(
            {},
            'keyboard.shallow',
            ({ events }) => { setBypassEvents(events.id !== 'CCAD_VIEWER'); }
        );
        return () => { pubsub.unsubscribe({}, 'keyboard.shallow'); };
    }, []);

    React.useEffect(() => {
        waitForNode(() => document.querySelector('.vcs-ccad__wrapper'))
            .then((node) => {
                contentRef.current = node;
                contentRef.current.addEventListener('mousemove', store.ui.controller.show);
                setShowBranding(true);
            });

        return () => {
            if (!contentRef.current) return;
            contentRef.current.removeEventListener('mousemove', store.ui.controller.show);
            setShowBranding(false);
        };
    }, []);

    const getDocumentSourceUrl = () => {
        if (sourceUrl !== store.file.sourceUrl) {
            store.startLoading();
            const pdfPromise = Settings.offlinePresentation
                ? Promise.resolve(store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    store.file.sourceUrl,
                    { response_type: 'data', viewable: 'off' }
                ));
            pdfPromise
                .then(data => {
                    setSourceUrl(data.url);
                    store.endLoading();
                })
                .catch(err => {
                    if (err?.status === 404) {
                        store.file.setNotFoundFile();
                    }
                });
        }
    };

    return (
        !sourceUrl
            ? null
            : <Suspense fallback={null}>
                <KeyHandler keyEvents={{ id: 'CCAD_VIEWER' }} />
                <Viewer fileName={sourceUrl} />
                {showBranding && (
                    createPortal(
                        <BrandingLogo branding={store.file.file.ownerInfo?.branding} />,
                        contentRef.current
                    )
                )}
                <DefaultController store={store} />
            </Suspense>
    );
};

export default observer(CCADViewer);

CCADViewer.propTypes = {
    store: PropTypes.object
};
