import React from 'react';
import { observable, action, makeObservable } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import MentionsField from './MentionsField';

import utils from './utils';

class CommentField extends React.Component {
    processing = false;
    comment = '';

    constructor (props) {
        super(props);

        makeObservable(this, {
            processing: observable,
            comment: observable,
            onCommentInput: action,
            setContent: action,
            setProcessing: action,
            clear: action
        });
    }

    get error () {
        return this.comment.length > 5000
            ? gettext('The maximum length is 5000 symbols.')
            : '';
    }

    setContent = content => {
        this.mentionsField.textareaElement.innerHTML = content;
        this.comment = content;
    };

    clear = () => {
        this.mentionsField.textareaElement.innerHTML = '';
        this.comment = '';
    };

    setFocus = () => this.mentionsField.textareaElement.focus();

    hasError = () => this.error;

    isProcessing = () => this.processing;

    get isEmpty () {
        return !utils.validateCommentWithoutHTML(this.comment);
    }

    getContent = () => this.mentionsField.textareaElement.innerHTML;

    getComment = () => this.comment;

    setProcessing = value => {
        this.processing = value;
    };

    onCommentInput = event => {
        this.comment = event.nativeEvent.target.innerHTML;
    };

    stopPropagation = e => e.stopPropagation();

    renderErrorMessage = () =>
        (this.error && <div
            className='validation-block'
            onClick={this.stopPropagation}
        >
            <span className='di-input-text di-input-error'>
                {this.error}
            </span>
        </div>);

    render () {
        return (
            <React.Fragment>
                <MentionsField
                    ref={mentionsField => { this.mentionsField = mentionsField; }}
                    placeholder={this.props.placeholder}
                    onChange={this.onCommentInput}
                    onKeyPress={this.onCommentInput}
                    onEnter={this.props.onEnter}
                    onEscape={this.props.onEscape}
                    onBlur={this.props.onBlur && this.props.onBlur}
                    commentsStore={this.props.commentsStore}
                />
                {this.renderErrorMessage()}
                <span className='comment-actions'>
                    { this.props.showButtonsPredicate(this.isEmpty) && this.props.children }
                </span>
            </React.Fragment>
        );
    }
}

export default observer(CommentField);

CommentField.defaultProps = {
    showButtonsPredicate: isEmpty => !isEmpty
};

CommentField.propTypes = {
    onEnter: PropTypes.func,
    onEscape: PropTypes.func,
    onBlur: PropTypes.func,
    showButtonsPredicate: PropTypes.func,
    placeholder: PropTypes.string,
    commentsStore: PropTypes.object.isRequired
};
