import React from 'react';
import PropTypes from 'prop-types';

import { ActionGroup } from '@vectorworks/vcs-ui/dist/lib/Toolbar';
import { parseActionMap } from '../utils/toolbar';

const ToolbarContextActions = ({ actions, hiddenActions, items }) => {
    const doAction = (key, context = null) => () => {
        return actions[key].action.call(context, items);
    };

    return (
        <ActionGroup
            orderReverseAtWidth={Settings.device.mdMax}
            actions={parseActionMap({
                actionsMap: actions,
                hiddenActions,
                doAction,
                createToolbarActionProps: action => ({
                    'data-what': 'fv-action',
                    'data-which': action.name
                })
            })}
        />
    );
};

ToolbarContextActions.propTypes = {
    actions: PropTypes.objectOf(PropTypes.object).isRequired,
    hiddenActions: PropTypes.array,
    items: PropTypes.array
};

export default ToolbarContextActions;
