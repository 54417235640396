import React from 'react';
import PropTypes from 'prop-types';
import { observer } from 'mobx-react';
import styled from 'styled-components';

import { TextOverflow } from '@vectorworks/vcs-ui/dist/lib/styles/common';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

import ExpandedComment from './ExpandedComment';
import VCDOCCommentsStore from '../preview/Sections/Comments/vcdoc-store';

const S = {
    Wrapper: styled.div`
        flex: 1;
        display: flex;
        flex-direction: column;
    `,
    PageComments: styled.div`
        display: flex;
        flex-direction: column;
        position: relative;
    `,
    PageHeader: styled.div`
        line-height: 32px;
        font-weight: bold;
        padding: 0 15px;
        cursor: pointer;

        > div {
            color: var(--text-secondary-color);
            border-top: 1px solid var(--border-color);
            display: flex;
            align-items: center;
            min-width: 0;

            > div {
                flex: 1;
            }
        }
    `,
    CommentCount: styled.span`
        flex-shrink: 0;
        display: flex;
        align-items: center;
    `,
    NoComments: styled.div`
        display: flex;
        flex-direction: column;
        align-items: center;
        color: var(--text-secondary-color);
        text-align: center;
        padding: 16px 50px;
    `
};

const NoComments = () => {
    return (
        <S.NoComments>
            <Icon icon='comments' size='64px' />
            <span>{gettext('This file version currently has no comments. Use the Comment tool to draw an area to comment on.')}</span>
        </S.NoComments>
    );
};

export const WebviewNoSets = () => {
    return (
        <S.NoComments>
            <Icon icon='comments' size='64px' />
            <div>{gettext('This Vectorworks file does not have any shared sets.')}</div>
            <div dangerouslySetInnerHTML={{
                __html: (
                    interpolate(
                        gettext(gettext('Use the %(trigger)s command to select sheet layers and/or worksheets to share with your collaborators.')), // eslint-disable-line
                        { trigger: `<b>${gettext('Cloud > Share Document...')}</b>` },
                        true
                    )
                )
            }} />
        </S.NoComments>
    );
};

const WebviewNoComments = observer(({ commentsStore }) => {
    const openInWeb = () => {
        window.vwAPI.OpenURL(`${window.location.origin}/links/${commentsStore.root.root.sharedSets.selected?.uuid}`);
    };

    return (
        <S.NoComments>
            <Icon icon='comments' size='64px' />
            <div>{gettext('The latest version of this shared set does not contain any comments.')}</div>
            <div
                onClick={openInWeb}
                dangerouslySetInnerHTML={{
                    __html: (
                        interpolate(
                            gettext(gettext('Go to the %(trigger)s to check comments on older versions.')), // eslint-disable-line
                            { trigger: `<a>${gettext('online viewer')}</a>` },
                            true
                        )
                    )
                }} />
        </S.NoComments>
    );
});

WebviewNoComments.propTypes = {
    commentsStore: PropTypes.object
};

const VCDOCCommentsList = observer(({ commentsStore, children }) => {
    const {
        showAllComments,
        pageCommentsMap,
        visiblePageNumber,
        tempCommentGeometry,
        pages
    } = commentsStore;

    const postCommentField = tempCommentGeometry ? children : null;
    const noComments = commentsStore.currentVersionComments.length === 0;

    if (commentsStore.isWebview) {
        if (commentsStore.root.root.sharedSets.sets.length === 0) {
            return <WebviewNoSets />;
        }

        if (noComments) {
            return <WebviewNoComments commentsStore={commentsStore} />;
        }
    }

    if (noComments && !tempCommentGeometry) {
        return commentsStore.allowComments ? <NoComments /> : null;
    }

    return (
        showAllComments
            ? (
                Array
                    .from(pageCommentsMap.entries())
                    .map(([pageNumber, pageComments]) => {
                        const isCurrentPage = pageNumber === visiblePageNumber;

                        if (
                            pageComments.length === 0 &&
                            (!tempCommentGeometry || !isCurrentPage)
                        ) {
                            return null;
                        }

                        const page = Object
                            .values(pages)
                            .find(p => p.pageNumber === pageNumber);

                        const _page = commentsStore.isWebview ? page : page.vcsPage;
                        const { sheetName, sheetTitle } = _page || { sheetName: pageNumber, sheetTitle: `Sheet - ${pageNumber}` };
                        const displayName = `${sheetName} ${sheetTitle}`;

                        return (
                            <S.PageComments
                                key={pageNumber}
                                onClick={() => commentsStore.toPage(pageNumber)}
                            >
                                <S.PageHeader id={`vcsPDF-viewer-comments-${pageNumber}`}>
                                    <div>
                                        <TextOverflow title={displayName}>
                                            {displayName}
                                        </TextOverflow>
                                        <S.CommentCount>
                                            <Icon icon='comment-indicator-16' size='16px' style={{ paddingRight: 2, paddingTop: 2 }} />
                                            <span>{pageComments.length}</span>
                                        </S.CommentCount>
                                    </div>
                                </S.PageHeader>
                                {isCurrentPage && (
                                    <React.Fragment>
                                        {postCommentField}
                                        {
                                            pageComments.map(comment => (
                                                <ExpandedComment
                                                    key={comment.id.toString()}
                                                    comment={comment}
                                                    commentsStore={commentsStore}
                                                />
                                            ))
                                        }
                                    </React.Fragment>
                                )}
                            </S.PageComments>
                        );
                    })
            )
            : (
                <React.Fragment>
                    {postCommentField}
                    {
                        (pageCommentsMap.get(visiblePageNumber) || []).map(comment => (
                            <ExpandedComment
                                key={comment.id.toString()}
                                comment={comment}
                                commentsStore={commentsStore}
                            />
                        ))
                    }</React.Fragment>
            )
    );
});

const CommentsList = ({ commentsStore, children }) => {
    return (
        <S.Wrapper>
            {
                commentsStore instanceof VCDOCCommentsStore
                    ? (
                        <VCDOCCommentsList commentsStore={commentsStore}>
                            {children}
                        </VCDOCCommentsList>
                    )
                    : (
                        commentsStore.filteredComments.map(comment => (
                            <ExpandedComment
                                key={comment.id.toString()}
                                comment={comment}
                                commentsStore={commentsStore}
                            />
                        ))
                    )
            }
        </S.Wrapper>
    );
};

export default observer(CommentsList);

CommentsList.propTypes = {
    commentsStore: PropTypes.object.isRequired
};
