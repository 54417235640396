import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';

const VideoPlayButton = ({ viewer }) =>
    <IconButton
        disabled={!viewer.canPlay}
        onClick={viewer.togglePause}
        ga-action='File_View_Controller'
        ga-label='Click_Toggle_Pause'
        data-what='controller-tool'
        data-which='toggle-pause'
    >
        <Icon icon={viewer.paused ? 'play' : 'pause'} />
    </IconButton>;

VideoPlayButton.propTypes = {
    viewer: PropTypes.object.isRequired
};

export default observer(VideoPlayButton);
