import React from 'react';

import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

const PAGE_WIDTH = Settings.device.isMobile ? 11 : 14;

const PDFPages = ({ viewer, store }) =>
    <span className='ctrl-tool page-control'>
        <IconButton
            onClick={viewer.prevPage}
            title={gettext('Previos page')}
            ga-action='File_View_Controller'
            ga-label='Click_Previous_Page'
            data-what='controller-tool'
            data-which='prev-page'
        >
            <Icon icon='page-up' />
        </IconButton>
        <input
            className='controller-input'
            ref={input => { viewer.input = input; }}
            defaultValue={viewer.currentPage}
            onChange={viewer.onInputChange}
            onBlur={viewer.changePage}
            onFocus={store.ui.controller.show}
            ga-action='File_View_Controller'
            ga-label='Click_Page_Input'
            style={{ maxWidth: `${viewer.numberOfPages ? viewer.currentPage.toString().split('').length * PAGE_WIDTH : PAGE_WIDTH}px` }}
        />
        <span>/</span>
        <span className='num-pages'>{viewer.numberOfPages}</span>
        <IconButton
            onClick={viewer.nextPage}
            title={gettext('Next page')}
            ga-action='File_View_Controller'
            ga-label='Click_Next_Page'
            data-what='controller-tool'
            data-which='next-page'
        >
            <Icon icon='page-down' />
        </IconButton>
    </span>;

PDFPages.propTypes = {
    viewer: PropTypes.object,
    store: PropTypes.object
};

export default observer(PDFPages);
