import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { toast as _toast } from '@vectorworks/vcs-ui/dist/lib/Toast/Toast';

import { requestDeepLink } from './api';
import { toast } from '../toast';
import CollapseToTarget from '../Components/CollapseToTarget';
import { isLinkView } from '../preview/link';

const ShowInNomad = function ({ node, link }) {
    const [closed, setClosed] = useState(false);

    const close = () => {
        setClosed(true);
    };

    return !closed
        ? <CollapseToTarget
            targetNode={node}
            delay={5000}
            content={({
                cssClass,
                callbackRef,
                style
            }) => <div className='notifyjs-corner notifyjs-corner--lower'>
                <div
                    className={`simple-notification ${cssClass}`}
                    ref={callbackRef}
                    style={style}
                >
                    <span className='icon icon-md icon-home' />
                    <a href={link} className='u'>{gettext('SHOW IN APP')}</a>
                    <span onClick={close} className='icon icon-md icon-close' />
                </div>
            </div>}
        />
        : null;
};

ShowInNomad.propTypes = {
    node: PropTypes.object.isRequired,
    link: PropTypes.string.isRequired
};

const getDeepLink = function () {
    const promise = new Promise(function (resolve, reject) {
        const deepLink = window.sessionStorage.getItem(`deepLink:${window.location.href}`);
        if (deepLink) {
            resolve(deepLink.split(':')[1]);
        } else {
            requestDeepLink()
                .then(({ shortLink }) => {
                    if (shortLink) {
                        window.sessionStorage.setItem(
                            `deepLink:${window.location.href}`,
                            shortLink
                        );

                        resolve(shortLink);
                    } else {
                        reject();
                    }
                })
                .catch(error => reject(error));
        }
    });

    return promise;
};

const isAllowed = function () {
    return Settings.device.isMobile &&
        isLinkView();
};

const showOpenInNomadPrompt = () => {
    isAllowed() && getDeepLink()
        .then(deepLink => _toast(
            {
                msg: () => <a href={deepLink}>{gettext('Open in Nomad')}</a>,
                immediate: true,
                type: 'success',
                className: 'nomad-style-toast',
                icon: 'none',
                duration: 1000 * 60 * 5
            },
            {},
            { position: 'top-center' }
        ))
        .catch(() => toast('ERROR_OCCURED'));
};

export default ShowInNomad;
export {
    requestDeepLink,
    showOpenInNomadPrompt
};
