import React from 'react';

import { runInAction } from 'mobx';
import { observer } from 'mobx-react';
import PropTypes from 'prop-types';

import { ActionGroup } from '@vectorworks/vcs-ui/dist/lib/Toolbar';
import { Icon } from '@vectorworks/vcs-ui/dist/lib/Basics/Icons/Icon';
import { IconButton } from '@vectorworks/vcs-ui/dist/lib/Buttons/IconButton';

import { FileTypes } from '../lib';

const ShowHistory = observer(({ store }) => {
    const toggleHistory = () => {
        runInAction(() => {
            store.ui.history = !store.ui.history;
        });
    };

    return (
        <IconButton
            toggled={store.ui.history}
            className='left-on-small'
            title={ store.ui.history ? gettext('Hide history') : gettext('Show history') }
            aria-label={ store.ui.history ? gettext('Hide history') : gettext('Show history') }
            onClick={toggleHistory}
            data-what='fv-action'
            data-which='toggle-history'
            ga-action='File_View'
            ga-label='Toggle_Versions'
        >
            <Icon icon='versions' />
        </IconButton>
    );
});

ShowHistory.propTypes = {
    store: PropTypes.object
};

const ShowComments = observer(({ store }) => {
    const toggleComments = () => {
        runInAction(() => {
            store.ui.comments = !store.ui.comments;
        });
    };

    return (
        <IconButton
            toggled={store.ui.comments}
            title={ store.ui.comments ? gettext('Hide comments') : gettext('Show comments') }
            aria-label={ store.ui.comments ? gettext('Hide comments') : gettext('Show comments') }
            onClick={toggleComments}
            data-what='fv-action'
            data-which='toggle-comments'
            ga-action='Comments'
            ga-label='Toggle_Comments'
        >
            <Icon icon='comments' />
        </IconButton>
    );
});

ShowComments.propTypes = {
    store: PropTypes.object
};

const CloseFile = observer(({ store }) => {
    const close = () => {
        store.ui.hide();
        window.FilePreview.close();
    };

    return store.ui.options.closeEnabled &&
        <IconButton
            title={ gettext('Close') }
            aria-label={ gettext('Close') }
            onClick={close}
            data-what='fv-action'
            data-which='close'
            ga-action='File_View'
            ga-label='Close_FileView'
        >
            <Icon icon='close' />
        </IconButton>;
});

CloseFile.propTypes = {
    store: PropTypes.object
};

const GlobalTools = props =>
    <ActionGroup>
        <ShowHistory {...props} />
        { props.store.file.file.fileType.type !== FileTypes.vcdoc && <ShowComments {...props} />}
        <CloseFile {...props} />
    </ActionGroup>;

GlobalTools.propTypes = {
    store: PropTypes.object
};

export default observer(GlobalTools);
export {
    ShowHistory,
    ShowComments,
    CloseFile
};
