import React, { Component } from 'react';

import { observable, action, makeObservable, runInAction } from 'mobx';
import { observer } from 'mobx-react';

import { commonPropTypes } from './utils';
import PDFObject from 'pdfobject/pdfobject.min.js';
import { queryString } from '../../lib';
import { loadPdfJs } from '../../lib/load-pdf';

const DEFAULT_SCALE_DELTA = 1.1;
const MIN_SCALE = 0.02;
const MAX_SCALE = 10.0;

class PDFViewer extends Component {
    currentPage = 1;
    numberOfPages = 0;
    percentage = 0;
    panMode = false;
    inputPage = 1;

    name = 'PDFViewer';
    sourceUrl = '';

    constructor (props) {
        super(props);

        makeObservable(this, {
            currentPage: observable,
            numberOfPages: observable,
            percentage: observable,
            panMode: observable,
            inputPage: observable,
            loadDocument: action,
            setPageWidthZoom: action,
            nextPage: action,
            prevPage: action,
            onInputChange: action,
            changePage: action,
            setActualPixelsSize: action,
            toggleHandTool: action,
            zoomOut: action,
            zoomIn: action
        });

        this.loading = true;
    }

    componentDidMount () {
        this.pdfPluginSupported = PDFObject.supportsPDFs;
        window.pdfViewer = this.pdfViewer;

        this.props.store.startLoading();
        loadPdfJs().then(PDFJS => {
            this.PDFJS = PDFJS;
            this.eventBus = new PDFJS.EventBus();
            this.pdfLinkService = new PDFJS.PDFLinkService({
                eventBus: this.eventBus
            });
            this.pdfViewer = new PDFJS.PDFViewer({
                container: this.container,
                eventBus: this.eventBus,
                linkService: this.pdfLinkService
            });

            this.handTool = new window.GrabToPan({
                element: this.container
            });

            this.pdfLinkService.setViewer(this.pdfViewer);
            this.pdfViewer.eventBus.on('pagesinit', this.setPageWidthZoom);

            this.loading = false;
            this.loadDocument();

            this.pdfViewer.eventBus.on('pagechanging', event => {
                runInAction(() => {
                    this.currentPage = event.pageNumber;
                    if (this.input) { // this.input is sometimes null
                        this.input.value = this.currentPage;
                    }
                    this.percentage = (this.pdfViewer._currentScale * 100).toFixed(0);
                });
            });
        });
    }

    componentDidUpdate () {
        this.loadDocument();
    }

    loadDocument = () => {
        if (
            !this.loading &&
            this.props.store.file.sourceUrl &&
            this.sourceUrl !== this.props.store.file.sourceUrl
        ) {
            this.sourceUrl = this.props.store.file.sourceUrl;

            this.props.store.startLoading();

            const pdfPromise = Settings.offlinePresentation
                ? Promise.resolve(this.props.store.file.offlineSourceUrlResp)
                : $.getJSON(queryString.buildUrl(
                    this.props.store.file.sourceUrl,
                    { response_type: 'data', viewable: 'off' }
                ));
            pdfPromise
                .then(async data => await this.PDFJS.getDocument(data.url).promise)
                .catch(err => {
                    if (err?.status === 404) {
                        this.props.store.file.setNotFoundFile();
                    }
                })
                .then(pdfDocument => {
                    this.pdfViewer.setDocument(pdfDocument);
                    this.pdfLinkService.setDocument(pdfDocument, null);
                    this.props.store.endLoading();
                }, () => { });
        }
    };

    componentWillUnmount () {
        this.pdfViewer.cleanup();
        this.container = undefined;
    }

    setPageWidthZoom = () => {
        this.props.store.endLoading();
        this.pdfViewer.currentScaleValue = 'page-width';
        this.numberOfPages = this.pdfViewer.pdfDocument.numPages || 0;
        this.currentPage = this.pdfViewer.currentPageNumber;
        this.percentage = (this.pdfViewer._currentScale * 100).toFixed(0);
    };

    nextPage = () =>
        this.currentPage + 1 <= this.numberOfPages &&
        this.pdfViewer.currentPageNumber++;

    prevPage = () =>
        this.currentPage - 1 > 0 &&
        this.pdfViewer.currentPageNumber--;

    onInputChange = e => {
        this.inputPage = e.target.value === '' ? this.inputPage : parseInt(e.target.value);
    };

    changePage = e => {
        e.preventDefault();
        this.props.store.ui.controller.show();
        const newPageNumber = this.inputPage;
        if (newPageNumber <= this.numberOfPages && newPageNumber > 0) {
            this.currentPage = newPageNumber;
            this.pdfViewer.currentPageNumber = newPageNumber;
        } else {
            this.inputPage = this.currentPage;
        };
    };

    zoomIn = (_, ticks = 1) => {
        let newScale = this.pdfViewer.currentScale;
        do {
            newScale = (newScale * DEFAULT_SCALE_DELTA).toFixed(3);
            newScale = Math.ceil(newScale * 100) / 100;
            newScale = Math.min(MAX_SCALE, newScale);
        } while (--ticks && newScale < MAX_SCALE);
        this.pdfViewer.currentScaleValue = newScale;
        this.percentage = (this.pdfViewer._currentScale * 100).toFixed(0);
    };

    zoomOut = (_, ticks = 1) => {
        let newScale = this.pdfViewer.currentScale;
        do {
            newScale = (newScale / DEFAULT_SCALE_DELTA).toFixed(3);
            newScale = Math.floor(newScale * 100) / 100;
            newScale = Math.max(MIN_SCALE, newScale);
        } while (--ticks && newScale > MIN_SCALE);
        this.pdfViewer.currentScaleValue = newScale;
        this.percentage = (this.pdfViewer._currentScale * 100).toFixed(0);
    };

    setActualPixelsSize = () => {
        if (this.pdfViewer.currentScaleValue === 'page-actual') {
            this.pdfViewer.currentScaleValue = 'page-width';
        } else {
            this.pdfViewer.currentScaleValue = 'page-actual';
        };
        this.percentage = (this.pdfViewer._currentScale * 100).toFixed(0);
    };

    toggleHandTool = () => {
        this.handTool.toggle();
        this.panMode = !this.panMode;
    };

    renderPDFjsViewer = () => {
        return (
            <div
                className='fileview-component-loader'
                data-what='file-viewer'
                {...this.props.controllerTogglers}
            >
                {this.props.children}

                <div
                    id='viewerContainerFileView'
                    ref={container => { this.container = container; }}
                >
                    <div id='viewerFileView' className='pdfViewer' />
                </div>
            </div>
        );
    };

    render () {
        return this.renderPDFjsViewer();
    }
}

export default observer(PDFViewer);

PDFViewer.propTypes = {
    ...commonPropTypes
};
